import { useEffect } from 'react';
import styled from 'styled-components';
import { useAudioContext } from '../../../../context/contexts/audio/AudioContext';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import { Button } from '../../../Buttons/Buttons';

const Pause = () => {

	const { paused, setPaused } = useGlobalContext();
	const {setMuted} = useAudioContext();

	useEffect(() => {
		if(!setMuted) return;
		if(paused){
			setMuted(true);
		}
		else{
			setMuted(false);
		}
	},[paused]);

	return ( 
		<>
			{ paused &&
				<Container>
					<h1>gepauzeerd</h1>
					<Button onClick={() => setPaused && setPaused(false)}>ga door</Button>
				</Container>
			}
		</>

	);
};

const Container = styled.div`
    position: absolute;
    inset:0;

    background: rgba(0,0,0,0.95);

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    & > h1 {
        margin-bottom: 20px;
        color: white;
    }
`;
 
export default Pause;