import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ReactGA from 'react-ga4';
import Plausible from 'plausible-tracker';


ReactGA.initialize('G-X70244TZWQ');
ReactGA.send('pageview');

// analytics

const { trackPageview } = Plausible({
	domain: 'arcadis.tqwi.nl',
	hashMode: true,
	// trackLocalhost: true
});

trackPageview();  

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
