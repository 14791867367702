import styled from 'styled-components';
import { Dialogue } from './components/dialogue/Dialogue';
import CameraFade from './components/CameraFade';
import { SideBar } from './components/sidebar/SideBar';
import Theme from '../../style/theme';
import BackButton from './components/BackButton';
import MessageBox from './components/messagebox/MessageBox';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { useEffect } from 'react';
import Pause from './components/pause/Pause';

const Interface = () => {
	
	return(
		<Container>
			<BackButton/>
			<CameraFade/>
			<Dialogue/>
			<MessageBox/>
			<FlexContainer>
				<SideBar/>
			</FlexContainer>
			<Pause/>
		</Container>
	);
};

// styled components

const Container = styled.div`
    position: absolute;
    height:100%; width:100%;
    left:0;top:0;
	pointer-events: none;

	& > * {
		pointer-events: all;
	}

	/* // Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		padding: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm}px;

	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px;

	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl}px;
	} */
`;

const FlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;

	pointer-events: none;

	& > * {
		pointer-events: all;
	}
`;

export default Interface;
