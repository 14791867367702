import styled from 'styled-components';
import { Button } from '../../../Buttons/Buttons';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import Theme from '../../../../style/theme';
import pause from '../../../../assets/images/pause.png';

export const Timer = () => {

	const {stopwatch, setPaused} = useGlobalContext();

	return (
		<Container>{stopwatch && `${stopwatch.minutes}:${stopwatch.seconds < 9 ? `0${stopwatch.seconds}` : stopwatch.seconds}`}<PauseButton iconURL={pause} onClick={() => setPaused && setPaused(true)}></PauseButton></Container>
	);
};

// styled components

const Container = styled.div`
	padding: 0;
	height: 50px;
	width: 100px;
	flex-shrink:0;
	margin-top: 0!important;
	border-radius: ${Theme.responsive.borderRadius.sm};
	border: 1px solid ${Theme.colors.primary};
	background-color: ${Theme.colors.primaryLighter};
	position: relative;
	pointer-events: none;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	@media (min-width: ${Theme.responsive.media.xs}){
			margin: ${Theme.responsive.whitespace.xs  / 2}px;
			font-size: 1rem;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			margin: ${Theme.responsive.whitespace.sm / 2}px;
			font-size: 1.2rem;
		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			margin: ${Theme.responsive.whitespace.lg / 2}px;
			font-size: 1.4rem;
		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			margin: ${Theme.responsive.whitespace.xl / 3}px;
			font-size: 1.4rem;

		}
`;

const PauseButton = styled(Button)`
	pointer-events: all;

	position: absolute;
	right:0;
	top:0;
	padding: 0;
	transform: translateX(110%);
	height: 100%;
	aspect-ratio: 1/1;
	width: unset;

	& > div{
		inset: 5px;
	}

`;